import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { getUserInfo } from "../../common/identity";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { BarChart } from "@mui/x-charts/BarChart";
import EmptyImage from "../../images/images/icons8-empty-96.png";

export default function OptionsPremiumSummary() {
  const [optionsPremiumSummary, setOptionsPremiumSummary] = useState([]);
  const [duration, setDuration] = useState("M");

  useEffect(() => {
    const fetchData = async () => {
      var user = await getUserInfo();
      try {
        const response = await fetch(
          `/data-api/rest/OptionsPremiumSummary?user_id=${user.userId}`
        );
        const data = await response.json();
        setOptionsPremiumSummary(data.value);
        //console.log(data.value);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const currentYear = new Date().getFullYear();

  const filteredData =
    duration === "M"
      ? optionsPremiumSummary.filter(
          (item) => item.transact_year === currentYear
        )
      : optionsPremiumSummary;

  //console.log(filteredData);

  const aggregatedData = filteredData.reduce((acc, item) => {
    const key = `${item.transact_year}-${item.transact_month}`;
    if (!acc[key]) {
      acc[key] = {
        transact_year: item.transact_year,
        transact_month: item.transact_month,
        net_options_premium: 0,
      };
    }
    acc[key].net_options_premium += item.net_options_premium;
    return acc;
  }, {});

  //console.log(aggregatedData);

  const resultArray = Object.values(aggregatedData);

  //console.log(resultArray);

  const formatLabelValue = (value) => {
    return `$${(value / 1000).toFixed(1)}k`;
  };

  if (optionsPremiumSummary.length === 0) {
    return (
      <Paper
        elevation={3}
        sx={{ borderRadius: 8, p: 2, my: 4, height: "400px" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 50 }}
        >
          <h2
            style={{
              textAlign: "center",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 700,
              fontSize: { xs: "0.9rem", sm: "1.25rem" },
            }}
          >
            Premium Summary
          </h2>

          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            sx={{ height: 50, display: { xs: "none", sm: "block" } }}
          >
            <Button
              onClick={() => setDuration("M")}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Month
            </Button>
            <Button
              onClick={() => setDuration("Y")}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Year
            </Button>
          </ButtonGroup>

          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            sx={{ height: 40, display: { xs: "block", sm: "none" } }}
          >
            <Button
              onClick={() => setDuration("M")}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              M
            </Button>
            <Button
              onClick={() => setDuration("Y")}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Y
            </Button>
          </ButtonGroup>
        </Stack>
        <Stack>
          <Box
            component="img"
            src={EmptyImage}
            alt="Empty Image"
            sx={{
              width: { xs: "96px", sm: "128px" },
              height: { xs: "96px", sm: "128px" },
              display: "block",
              margin: "auto",
            }}
          />
          <h2
            style={{
              textAlign: "center",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 300,
              fontSize: "1rem",
            }}
          >
            No open options contracts found!
          </h2>
        </Stack>
      </Paper>
    );
  }

  return (
    <>
      <Paper
        elevation={3}
        sx={{ borderRadius: 8, p: 2, my: 4, height: "400px" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 50 }}
        >
          <h2
            style={{
              textAlign: "center",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 700,
              fontSize: { xs: "0.9rem", sm: "1.25rem" },
            }}
          >
            Options Premium
          </h2>

          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            sx={{ height: 50, display: { xs: "none", sm: "block" } }}
          >
            <Button
              onClick={() => setDuration("M")}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Month
            </Button>
            <Button
              onClick={() => setDuration("Y")}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Year
            </Button>
          </ButtonGroup>

          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            sx={{ height: 40, display: { xs: "block", sm: "none" } }}
          >
            <Button
              onClick={() => setDuration("M")}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              M
            </Button>
            <Button
              onClick={() => setDuration("Y")}
              sx={{ fontSize: "0.75rem", fontWeight: 600 }}
            >
              Y
            </Button>
          </ButtonGroup>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          gap={4}
          sx={{ height: 25 }}
        >
          <h4
            style={{
              textAlign: "left",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "0.75rem", sm: "1rem" },
            }}
          >
            <b>YTD: </b>
            {formatLabelValue(
              resultArray.reduce(
                (acc, item) => acc + item.net_options_premium,
                0
              )
            )}
          </h4>
          <h4
            style={{
              textAlign: "left",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "0.75rem", sm: "1rem" },
            }}
          >
            <b>MTD: </b>
            {formatLabelValue(
              resultArray.find(
                (item) =>
                  `${item.transact_year}-${item.transact_month}` ===
                  `${currentYear}-${new Date().getMonth() + 1}`
              )?.net_options_premium ?? 0
            )}
          </h4>
        </Stack>

        <Stack alignItems="center" spacing={1}>
          <Paper
            elevation={0}
            sx={{
              height: 335,
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              crollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#ffffff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <BarChart
              dataset={resultArray}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey:
                    duration === "M" ? "transact_month" : "transact_year",
                },
              ]}
              series={[{ dataKey: "net_options_premium", fill: "#8884d8" }]}
              borderRadius={10}
            />
          </Paper>
        </Stack>
      </Paper>
    </>
  );
}
