import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { getUserInfo } from "../../common/identity";
import { BarChart } from '@mui/x-charts/BarChart';

export default function DividendSummary() {
  const [dividendSummary, setDividendSummary] = useState([]);
  //const [duration, setDuration] = useState("M");
  const duration = "M";

  useEffect(() => {
    const fetchData = async () => {
      var user = await getUserInfo();
      try {
        const response = await fetch(
          `/data-api/rest/DividendSummary?user_id=${user.userId}&display_currency=SGD`
        );
        const data = await response.json();
        setDividendSummary(data.value);
        //console.log(data.value);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const currentYear = new Date().getFullYear();

  const filteredData = 
    duration === "M"
      ? dividendSummary.filter(
        (item) => item.pay_year === currentYear
      )
      : dividendSummary;

  console.log(filteredData)

  //const formatLabelValue = (value) => {
  //  return `$${(value).toFixed(2)}`;
  //};

  if (dividendSummary.length === 0) {
    return (
      <Paper
      elevation={3}
      sx={{ borderRadius: 8, p: 2, my: 4, height: "400px" }}
    >
      </Paper>
    )
  }

  //const month_chart_label = []
  //const symbolData = []
  const seriesData = []

  if (dividendSummary.length > 0) {
    // Process for month view
    const uniqueMonths = [...new Set(filteredData.map(item => item.pay_month))];
    const uniqueSymbols = [...new Set(filteredData.map(item => item.symbol))];
  
    const chartData = uniqueMonths.map(month => {
      const monthData = { pay_month: month };
      uniqueSymbols.forEach(symbol => {
        monthData[symbol] = 0;
      });
      return monthData;
    });
  
    filteredData.forEach(item => {
      const monthData = chartData.find(data => data.pay_month === item.pay_month);
      if (monthData) {
        monthData[item.symbol] = item.display_amount;
      }
    });
  
    console.log(chartData);
  
    const month_chart_label = chartData.map(item => item.pay_month);
  
    const month_chart_symbol = Object.keys(chartData[0]).filter(key => key !== 'pay_month');
  
    const symbolData = month_chart_symbol.reduce((acc, symbol) => {
      acc[symbol] = new Array(month_chart_label.length).fill(0);
      return acc;
    }, {});
  
    // Populate arrays with values
    chartData.forEach((item, index) => {
      month_chart_symbol.forEach(symbol => {
        symbolData[symbol][index] = item[symbol];
      });
    });
    
    //console.log(month_chart_label)
    //console.log(symbolData['PFF']);

    const seriesData = Object.keys(symbolData).map(symbol => ({
      data: symbolData[symbol],
      label: symbol,
      stack: 'total'
    }));
    
    console.log(seriesData);
  }



  return (
    <Paper elevation={3} sx={{ borderRadius: 8, p: 2, my: 4, height: "400px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: 50 }}
      >
        <h2
          style={{
            textAlign: "center",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "0.9rem", sm: "1.25rem" },
          }}
        >
          Dividends
        </h2>
      </Stack>

      <Stack alignItems="center" spacing={1}>
          <Paper
            elevation={0}
            sx={{
              height: 335,
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              crollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#ffffff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
             <BarChart
/*             series={[
              {
                  "data": [
                      54.27,
                      49.45,
                      52.31,
                      64.89,
                      64.37,
                      77.88,
                      74.48,
                      76.19,
                      112.75,
                      110.58,
                      105.81
                  ],
                  "label": "JEPI",
                  "stack": "total"
              },
              {
                  "data": [
                      19.28,
                      26.52,
                      31.34,
                      41.06,
                      48.31,
                      56.76,
                      66.75,
                      74.16,
                      74.16,
                      76.78,
                      79.25
                  ],
                  "label": "O",
                  "stack": "total"
              },
              {
                  "data": [
                      0,
                      24.88,
                      28.82,
                      29.35,
                      34.51,
                      41.46,
                      49.33,
                      57.36,
                      67.49,
                      74.64,
                      83.92
                  ],
                  "label": "PFF",
                  "stack": "total"
              },
              {
                  "data": [
                      0,
                      10.19,
                      12.49,
                      17.62,
                      28.92,
                      34.78,
                      35.57,
                      38.47,
                      38.21,
                      38.58,
                      67.12
                  ],
                  "label": "TLT",
                  "stack": "total"
              },
              {
                  "data": [
                      0,
                      0,
                      71.9,
                      0,
                      0,
                      142.4,
                      0,
                      0,
                      177.65,
                      0,
                      0
                  ],
                  "label": "MSFT",
                  "stack": "total"
              },
              {
                  "data": [
                      0,
                      0,
                      111.72,
                      0,
                      0,
                      120.31,
                      0,
                      0,
                      111.72,
                      0,
                      0
                  ],
                  "label": "O9P.SI",
                  "stack": "total"
              },
              {
                  "data": [
                      0,
                      0,
                      0,
                      75.56,
                      0,
                      0,
                      97.17,
                      0,
                      0,
                      90.64,
                      0
                  ],
                  "label": "VOO",
                  "stack": "total"
              },
              {
                  "data": [
                      0,
                      0,
                      0,
                      0,
                      42,
                      0,
                      0,
                      44,
                      0,
                      0,
                      0
                  ],
                  "label": "O39.SI",
                  "stack": "total"
              },
              {
                  "data": [
                      0,
                      0,
                      0,
                      0,
                      0,
                      0,
                      395.84,
                      0,
                      0,
                      0,
                      0
                  ],
                  "label": "BABA",
                  "stack": "total"
              }
          ]} */
         series={seriesData.map(item => {
            return {
              data: item.data,
              label: item.label,
              stack: 'a'
            }
          })}
         
            xAxis={[{data: ['Jan','Feb','Mar','Apr','May','Jun','Jul', 'Aug', 'Sep','Oct','Nov','Dec'], scaleType: 'band'}]}
            borderRadius={10}
            />
            </Paper>
            </Stack>

    </Paper>
  );
}
